.container .hex::before{
    content: '';
    position: absolute;
    bottom: -60px;
    width: 100%;
    height:50px;
    background: radial-gradient(rgba(0,0,0,0.5),transparent,transparent);
    border-radius: 50%;
    transition: 0.5s;
}
.container .hex:hover::before{
    opacity: 0.8;
    transform: scale(0.8);
}
.container .hex .shape{
    transition: 0.5s;
}
.container .hex:hover .shape{
    transform: translateY(-30px);
}
.container .hex .shape .content{
    background: linear-gradient(45deg,#03a9f4,rgba(3,169,244,0.5));
    opacity: 0;
}
.container .hex:hover .shape .content{
    
    opacity: 1;
}
