@tailwind base;
@tailwind components;
@tailwind utilities;



@layer utilities {


  .pentagon-clip-path {

    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);

  }

}
.popup-content{
  width: 400px !important;
}

/* .button1-popup-content{
  width: 300px !important;
  height: 400px !important;
  overflow-y: scroll;
} */

body {
  font-family: "Space Mono", monospace;
  font-style: normal;
  font-weight: normal;
}
